import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Container from "../Container"
import Row from "../Row"
import Col from "../Col"
import CookieImg from "../../images/cookie.png"
import CloseIcon from "../../images/close.png"
import { BREAKPOINTS } from "../../utils/styled_config"
import CookieSettingModal from "./CookieSettingModal"


const CookiePolicy = () => {
  const [show, setShow] = useState(!localStorage.getItem("cookiesAccepted"))
  const [modal, setModal] = useState()
  const [modalShow, setModalShow] = useState(false)
  useEffect(() => {
    if (localStorage.getItem("cookiesAccepted")) {
      addGtagScript()
    }
  }, [])

  const handleAccept = () => {
    setShow(false)
    localStorage.setItem("cookiesAccepted", "true")
    addGtagScript()
  }

  const addGtagScript = () => {
    if (!document.querySelector('script[src="https://www.googletagmanager.com/gtag/js?id=G-51SENWZFGR"]')) {
      const script = document.createElement("script")
      script.src = "https://www.googletagmanager.com/gtag/js?id=G-51SENWZFGR"
      script.async = true
      document.head.appendChild(script)

      const inlineScript = document.createElement("script")
      inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-51SENWZFGR');
      `
      document.head.appendChild(inlineScript)
    }
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleModalClose = () => {
    setModalShow(false)
  }

  const handleSaveSettings = () => {
    setShow(false)
    localStorage.setItem("cookiesAccepted", "true")
    addGtagScript()
    handleModalClose()
  }

  const handleModalClick = () => {
    setModalShow(true)
    setModal(true)
  }
  return (
    show && (
      <Div>
        <Container>
          <Row align="center">
            <CustomCol col>
              <Row align="center" style={{ position: "relative" }}>
                <Col md="auto">
                  <img src={CookieImg} alt="Cookie" style={{ marginBottom: "0" }} />
                </Col>
                <Col md={9}>
                  <p>This website uses cookies to enhance user experience and to analyze performance and traffic on our website. We also share information about your use of our site with our social media, advertising and analytics partners.</p>
                </Col>
                <MobileIconButton onClick={handleClose}>
                  <img src={CloseIcon} alt="Close" />
                </MobileIconButton>
              </Row>
            </CustomCol>
            <Col col={12} md="auto">
              <CustomRow className="gx-5">
                <Col col md={12} lg="auto">
                  <FilledButton onClick={handleAccept}>Accept</FilledButton>
                </Col>
                <Col col md={12} lg="auto">
                  <BorderedButton 
                    onClick={() => {
                      handleModalClick()
                    }}>
                      Settings
                  </BorderedButton>
                </Col>
                <HideCol col md="auto">
                  <IconButton onClick={handleClose}>
                    <img src={CloseIcon} alt="Close" />
                  </IconButton>
                </HideCol>
              </CustomRow>
            </Col>
          </Row>
          <CookieSettingModal 
            show={modalShow}
            handleClose={handleModalClose}
            handleGtag={addGtagScript} 
            onSaveSettings={handleSaveSettings}
          />
        </Container>
      </Div>
    )
  )
}

const CustomRow = styled(Row)`
  gap: 8px;
  button {
    cursor: pointer;
  }
  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    > div {
      padding: 0;
    }
  }
  @media screen and (max-width: ${BREAKPOINTS.lg}) {
    button {
      width: 100%;
    }
  }
`
const CustomCol = styled(Col)`
  @media screen and (max-width: ${BREAKPOINTS.lg}) {
    margin-bottom: 24px;
  }
`
const HideCol = styled(Col)`
  @media screen and (max-width: ${BREAKPOINTS.md}) {
    display:none;
  }
`

const Div = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 2;
  padding: 24px 0;
  p {
    margin-bottom: 0;
    color: #23292E;
  }
`

const FilledButton = styled.button`
  background-color: #697E8E;
  color: #fff;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  border: 1px solid #697E8E;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #889BA8;
  }
`
const BorderedButton = styled.button`
  background-color: transparent;
  border: 1px solid #697E8E;
  color: #697E8E;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  border: 1px solid #697E8E;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #ECEFF2;
  }
`

const IconButton = styled.button`
  background-color: transparent;
  border: 0;
  color: #697E8E;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 0;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}) {
    display: none;
  }
`

const MobileIconButton = styled.button`
  background-color: transparent;
  border: 0;
  color: #697E8E;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 10px;
  img {
    margin-bottom: 0;
  }
  @media screen and (min-width: ${BREAKPOINTS.md}) {
    display: none;
  }
`

export default CookiePolicy
