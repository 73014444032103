import React from "react"
import Modal from "react-bootstrap/Modal"
import CloseIcon from "../Icons/CloseIcon"
import styled from "styled-components"
import theme from "../../utils/theme"
import CookieImg from "../../images/cookie.png"
import Col from "../Col"
import Row from "../Row"
import { BREAKPOINTS } from "../../utils/styled_config"
const ModalBootstrap = ({ handleClose, children, show }) => {
  return (
    <CookieModal show={show} onHide={handleClose} centered>
      <Modal.Title>
        <CustomRow align="center" justify="center">
            <CustomCol col>
              <img src={CookieImg} alt="Cookie" style={{ marginBottom: "0" }} />
              <Span>Cookie Settings</Span>
            </CustomCol>
            <Col col="auto">
              <Close onClick={handleClose}>
                <CloseIcon />
              </Close>
            </Col>
        </CustomRow>
      </Modal.Title>
      <Modal.Body style={{ padding: "24px 0 0 0" }}>
        {children}
      </Modal.Body>
    </CookieModal>
  )
}
const CustomCol = styled(Col)`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const CustomRow = styled(Row)`
  padding-bottom: 24px;
  border-bottom: 1px solid #D6DCE1;
`;

const Span = styled.span`
  font-size: 24px;
  font-weight: bold;
  @media screen and (max-width: ${BREAKPOINTS.sm}) {
    font-size: 20px;
  }
`
const CookieModal = styled(Modal)`
  .modal-content {
    background-color: #fff;
    * {
      color: #23292E;
    }
    p {
      margin-bottom: 0;
    }
  }
`
const Close = styled.button`
  height: 40px;
  width: 40px;
  background: none;
  color: #fff;
  border: 0;
  font-size: 24px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  border-radius: 100%;
  transition: ${theme.transition};

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`

export default ModalBootstrap
