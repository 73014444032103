import React, { useState } from "react"
import styled from "styled-components"
import ModalBootstrap from "./ModalBootstrap"
import Switch from "./Switch";
import Col from "../Col"
import Row from "../Row"

const CookieSettingModal = ({
  show,
  handleClose, 
  onSaveSettings,
  handleGtag
}) => {
  
  const [checked, setChecked] = useState(true);

  const handleChange = () => {
     setChecked((s) => !s);
  };

  const handleSaveSettings = () => {
    if (checked) {
      onSaveSettings();
    }
    handleClose()
  }

  return (
    <StyledModal show={show} handleClose={handleClose}>
      
      <Row mb={6}>
        <Col lg={12}>
          <H4>Cookie Consent Manager</H4>
          <p>We use three types of cookies (required, functional, and advertising) to improve your experience on our site.  You can customize which types are used through 'Cookie Settings' or accept them all with 'Accept All Cookies'</p>
        </Col>
      </Row>
      <Row mb={6}>
        <Col lg={12}>
          <H4 style={{ marginBottom: "24px" }}>Manage Consent Preference</H4>
          <Group>
            <H5>Required Cookies</H5>
            <p style={{ color: "#2196F3", fontWeight: "500" }}> Always Active</p>
          </Group>
          <p>Required cookies are necessary for basic website functionality. Some examples include session cookies needed to transmit the website, authentication cookies, and security cookies.</p>
        </Col>
      </Row>
      <Row mb={6} style={{ borderBottom: "1px solid #D6DCE1", paddingBottom: "24px" }}>
        <Col lg={12}>
          <Group>
            <H5>Advertising Cookies</H5>
            <Switch
            checked={checked}
            onChange={handleChange}
            round
            ></Switch>
          </Group>
          <p>These cookies help companies target ads based on what you seem interested in. This means the ads you see should be more relevant to you.</p>
        </Col>
      </Row>
      <Row justify="end">
        <Col col="auto">
          <FilledButton onClick={handleSaveSettings}>Save Settings</FilledButton>
        </Col>
      </Row>
     
    </StyledModal>
  )
}

const Group = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`
const StyledModal = styled(ModalBootstrap)`
  bottom: 0;
  top: inherit;
  background-color: #fff;
`

const H4 = styled.p`
  font-size: 22px;
  margin-bottom: 16px;
  font-weight: 500;
`
const H5 = styled.p`
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: 600;
`
const FilledButton = styled.button`
  background-color: #697E8E;
  color: #fff !important;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  border: 1px solid #697E8E;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: #889BA8;
  }
`

export default CookieSettingModal
